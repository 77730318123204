/**
 * 固定ページ系のテンプレート用ファイル(XDのabout,termsなど)
 */
import React from 'react';
import styled from 'styled-components';
import { graphql, PageProps } from 'gatsby';
import Layout from '@/templates/Layout';
import Sidebar from '@/organisms/Sidebar';
import Seo from '@/components/Seo';

export const FullWidthContent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;

  @media (max-width: 767px) {
    flex-direction: column;
    margin-top: 0;
  }
`;

export const LeftColumn = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const SidebarWrapper = styled.div`
  display: flex;
  
  @media (max-width: 767px) {
    padding: 0 20px;
  }
`;

const Inner = styled.div`
  max-width: 875px;
  padding: 50px 80px 60px;
  background: #fff;

  @media (max-width: 767px) {
    padding: 50px 20px 50px;
  }

  h1 {
    font-size: 30px;

    @media (max-width: 767px) {
      font-size: 22px;
    }
  }

  h2 {
    padding-bottom: 20px;
    font-size: 26px;
    font-weight: bold;
    border-bottom: 2px solid #1b1b1b;
    margin-top: 55px;

    @media (max-width: 767px) {
      padding-bottom: 16px;
      font-size: 20px;
    }
  }

  h3 {
    padding-left: 15px;
    margin-top: 50px;
    font-size: 24px;
    font-weight: bold;
    border-left: 5px solid #adb5bb;

    @media (max-width: 767px) {
      padding-left: 12px;
      font-size: 18px;
      line-height: 1.6;
    }
  }

  p {
    margin-top: 30px;
    font-size: 17px;
    line-height: 2;

    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 1.9;
    }
  }

  div {
    margin-top: 40px;
  }

  ol {
    margin-top: 30px;
    list-style: decimal inside;
  }

  li {
    padding-left: 1em;
    font-size: 17px;
    line-height: 2;
    text-indent: -1.1em;

    @media (max-width: 767px) {
      font-size: 15px;
      line-height: 1.9;
    }
  }

  a {
    color: #469af6;
    text-decoration: none;
  }
`;

const Page: React.FC<PageProps<GatsbyTypes.PageQuery>> = ({
  data,
  location,
}) => {
  const { fields, frontmatter, html } =
    data.markdownRemark as GatsbyTypes.MarkdownRemark;
  const { pathname, href, origin } = location;

  return (
    <>
      <Seo
        url={href || (fields?.slug as string)}
        origin={origin}
        uniqueTitle={
          frontmatter?.title === 'POSTDについて' ? 'About' : frontmatter?.title
        }
      />
      <Layout fullwidth>
        <FullWidthContent>
          <LeftColumn>
            <Wrapper>
              <Inner>
                <h1>{frontmatter?.title}</h1>
                {/* biome-ignore lint/security/noDangerouslySetInnerHtml: Remarkから生成されたHTMLを直接表示する */}
                <div dangerouslySetInnerHTML={{ __html: html || '' }} />
              </Inner>
            </Wrapper>
          </LeftColumn>
          <SidebarWrapper>
            <Sidebar pathname={pathname} />
          </SidebarWrapper>
        </FullWidthContent>
      </Layout>
    </>
  );
};

export const query = graphql`
  query Page($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
      }
      fields {
        slug
      }
    }
  }
`;

export default Page;
