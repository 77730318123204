import React from 'react';
import styled from 'styled-components';

const Text = styled.p`
  font-size: 14px;
  color: #666;

  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

const ArticleDate: React.FC = ({ children }) => <Text>{children}</Text>;

export default ArticleDate;
