import React from 'react';
import styled from 'styled-components';

export type OriginalData = {
  author: string;
  date: string;
  title: string;
  link: string;
};

type Props = {
  original: OriginalData;
  authorLink: string;
  authorImagePublicUrl: string;
  isRequestedArticle: boolean;
};

const Wrapper = styled.div`
  padding-bottom: 18px;
  border-bottom: 1px solid #eee;

  @media (max-width: 767px) {
    padding-bottom: 14px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background-color: #f6f6f6;

  @media (max-width: 767px) {
    align-items: flex-start;
    padding: 10px;
  }
`;

const OriginalIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 15px;
  border-radius: 50%;

  @media (max-width: 767px) {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
`;

const TextArea = styled.div`
  font-size: 14px;
  line-height: 1.6;
  color: #666;

  @media (max-width: 767px) {
    font-size: 13px;
  }
`;

const Information = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

const Note = styled.p`
  margin-top: 12px;
  font-size: 12px;

  @media (max-width: 767px) {
    margin-top: 10px;
    font-size: 10px;
  }
`;

const Author = styled.p`
  margin-left: 5px;

  @media (max-width: 767px) {
    margin-top: 10px;
    margin-left: 0;
  }
`;

const ExternalLink = styled.a`
  color: #469af6;
  text-decoration: none;
`;

const Text = styled.p`
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.6;
  color: #707070;
  text-align: center;

  @media (max-width: 767px) {
    margin-top: 18px;
    font-size: 13px;
    text-align: left;
  }
`;

const LinBreakPc = styled.br`
  @media (max-width: 767px) {
    display: none;
  }
`;

const OriginalInfo: React.FC<Props> = (props) => {
  const { original, authorLink, authorImagePublicUrl, isRequestedArticle } =
    props;

  return (
    <Wrapper>
      <Content>
        {authorImagePublicUrl && (
          <OriginalIcon src={authorImagePublicUrl} alt={original.author} />
        )}
        <TextArea>
          <Information>
            <ExternalLink href={original.link}>{original.title}</ExternalLink>
            <Author>
              {original.date && `(${original.date})`}
              {original.author ? (
                <>
                  by{' '}
                  <ExternalLink href={authorLink}>
                    {original.author}
                  </ExternalLink>
                </>
              ) : (
                <>著者プロフィール非公開</>
              )}
            </Author>
          </Information>
          <Note>本記事は、原著者の許諾のもとに翻訳・掲載しております。</Note>
        </TextArea>
      </Content>
      {isRequestedArticle && (
        <Text>
          本エントリは
          <ExternalLink
            href="https://github.com/postdcc/translation_request"
            target="_blank"
            rel="noopener noreferrer"
          >
            翻訳リクエスト
          </ExternalLink>
          より投稿いただきました。
          <LinBreakPc />
          ありがとうございます！リクエストまだまだお待ちしております！
        </Text>
      )}
    </Wrapper>
  );
};

export default OriginalInfo;
