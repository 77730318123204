import React from 'react';
import styled from 'styled-components';

const Text = styled.h1`
  font-size: 30px;
  color: #1b1b1b;

  @media (max-width: 767px) {
    font-size: 22px;
  }
`;

const ArticleTitle: React.FC = ({ children }) => <Text>{children}</Text>;

export default ArticleTitle;
