import React from 'react';
import styled from 'styled-components';
import type { Supervisor } from '@/services/supervisors';

type Props = {
  supervisor: Supervisor;
};

const Wrapper = styled.div`
  background-color: #f6f6f6;
  border-bottom: 1px solid #eee;
  padding: 30px 20px;
  margin-top: 50px;
  display: grid;
  align-items: center;
  grid-template-columns: 0.2fr 0.8fr;
  grid-template-rows: 0.1fr 0.2fr;
  gap: 20px 20px;
  grid-template-areas:
      "header header"
      "img name"
      "introduce introduce";

  @media (min-width: 768px) {
    padding:25px 5%;
    align-items: normal;
    grid-template-columns: 0.2fr 0.8fr;
    gap: 0px 25px;
    grid-template-areas: 
        ". header"
        "img name"
        "img introduce"
  }
`;

const Header = styled.span`
  grid-area: header;
  font-weight: bold;
  font-size: 20px;
  @media (min-width: 768px) {
    font-weight: normal;
    font-size: 16px;
  }
`;

const ImageWrapper = styled.div`
  grid-area: img;
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
`;

const NameWrapper = styled.div`
  grid-area: name;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
  white-space: pre-wrap;
`;

const Name = styled.b`
  line-height: 2;
`;

const Introduce = styled.div`
  grid-area: introduce;
  font-size: 14px;
  line-height: 1.5;
  white-space: pre-wrap;
  @media (min-width: 768px) {
    margin-top: 15px;
  }
`;
const SocialLinkWrapper = styled.ul`
  margin-top: 8px;
`;
const SocialLinkItem = styled.li`
  line-height: 1.5;
`;
const SocialLink = styled.a`
  color: #469af6;
`;

const SupervisorInfo: React.FC<Props> = (props: Props) => {
  const { supervisor } = props;
  return (
    <Wrapper data-testid="supervisor-info">
      <Header>監修者</Header>
      <ImageWrapper>
        <Image src={supervisor.imageUrl} alt={`監修者_${supervisor.name}`} />
      </ImageWrapper>
      <NameWrapper>
        <Name data-testid="supervisor-name">{supervisor.name}</Name>
        <br />
        {supervisor.description}
      </NameWrapper>
      <Introduce>
        {supervisor.introduce}
        <SocialLinkWrapper>
          {supervisor.socialLinks?.map((socialLink) => (
            <SocialLinkItem key={socialLink.url}>
              {socialLink.name}:{' '}
              <SocialLink
                href={socialLink.url}
                title={socialLink.name}
                rel="noopener external noreferrer"
                target="_blank"
                data-wpel-link="external"
              >
                {socialLink.label}
              </SocialLink>
            </SocialLinkItem>
          ))}
        </SocialLinkWrapper>
      </Introduce>
    </Wrapper>
  );
};

export default SupervisorInfo;
