import yosukeFurukawaImage from '@/images/supervisor_yosuke_furukawa.jpeg';

export type SocialLink = {
  name: string;
  url: string;
  label: string;
};

export type Supervisor = {
  name: string;
  description: string;
  introduce: string;
  imageUrl: string;
  socialLinks: SocialLink[];
};

/**
 * 監修者一覧
 */
const supervisors: Record<string, Supervisor> = {
  'yosuke-furukawa': {
    name: '古川陽介',
    description:
      '株式会社リクルート　プロダクト統括本部　プロダクト開発統括室　グループマネジャー\n株式会社ニジボックス　デベロップメント室 室長\nNode.js 日本ユーザーグループ代表',
    introduce:
      '複合機メーカー、ゲーム会社を経て、2016年に株式会社リクルートテクノロジーズ(現リクルート)入社。\n現在はAPソリューショングループのマネジャーとしてアプリ基盤の改善や運用、各種開発支援ツールの開発、またテックリードとしてエンジニアチームの支援や育成までを担う。\n2019年より株式会社ニジボックスを兼務し、室長としてエンジニア育成基盤の設計、技術指南も遂行。\nNode.js 日本ユーザーグループの代表を務め、Node学園祭などを主宰。',
    imageUrl: yosukeFurukawaImage,
    socialLinks: [
      {
        name: 'Twitter',
        label: '@yosuke_furukawa',
        url: 'https://twitter.com/yosuke_furukawa',
      },
      {
        name: 'Github',
        label: 'yosuke-furukawa',
        url: 'https://github.com/yosuke-furukawa',
      },
    ],
  },
};

/**
 * 監修者情報を返却する
 * 途中から本機能が追加されたことから、Markdown内で明示的に `supervisor` が指定されていない場合はデフォルトの古川さんを返却する仕様としている
 */
export const getSupervisor = (name: GatsbyTypes.Maybe<'String'>) => {
  if (typeof name !== 'string') return supervisors['yosuke-furukawa']; // default
  return supervisors[name];
};
