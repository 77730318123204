/**
 * 記事ページ系のテンプレート用ファイル(XDのarticle)
 */
import React from 'react';
import { graphql, PageProps } from 'gatsby';
import styled from 'styled-components';
import Layout from '@/templates/Layout';
import {
  FullWidthContent,
  LeftColumn,
  Wrapper,
  SidebarWrapper,
} from '@/templates/page';
import ArticleInfo from '@/molecules/ArticleInfo';
import Sidebar from '@/organisms/Sidebar';
import OriginalInfo, { OriginalData } from '@/organisms/OriginalInfo';
import ShareButtons from '@/organisms/ShareButtons';
import Seo from '@/components/Seo';
import TextPage from '@/services/textPageStyles';
import SupervisorInfo from '@/organisms/SupervisorInfo';
import { getSupervisor } from '@/services/supervisors';

const Inner = styled.div`
  max-width: 875px;
  padding: 50px 80px 60px;
  background-color: #fff;

  @media (max-width: 1236px) {
    max-width: calc(100 / 1400 * 951vw);
  }

  @media (max-width: 767px) {
    max-width: 100%;
    padding: 50px 20px 50px;
  }
`;

// TODO: 記事 postgresql-unknown-features においてMV指定となった画像が縦長だったことに伴う対応
// max-heightはこの対応に併せて行っただけなので、適正値は別途デザインレベルで再決定したほうが良い
const MainVisual = styled.img`
  width: 100%;
  height: auto;
  max-height: 350px;
  margin-top: 20px;
  object-fit: contain;

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    max-height: 350px;
    object-fit: contain;
  }
`;

const OriginalWrapper = styled.div`
  margin-top: 18px;

  @media (max-width: 767px) {
    margin-top: 14px;
  }
`;

const ShareButtonsWrapper = styled.div`
  margin-top: 60px;

  @media (max-width: 767px) {
    margin-top: 50px;
  }
`;

const ArticlePage: React.FC<PageProps<GatsbyTypes.ArticlePageQuery>> = ({
  data,
  location,
}) => {
  const { fields, frontmatter, html, excerpt } =
    data.markdownRemark as GatsbyTypes.MarkdownRemark;

  const originalData = frontmatter?.original as OriginalData;
  const siteTags = data.allSiteTag.nodes;
  const { pathname, href, origin } = location;
  const tagsMap = new Map<string, string>();
  siteTags.map((tag) => tagsMap.set(tag.slug || '', tag.title || ''));

  const imageUrl = (() => {
    if (frontmatter?.image_public_url) return frontmatter.image_public_url;
    if (frontmatter?.image?.publicURL) return frontmatter.image.publicURL;
    return undefined;
  })();

  const supervisor = getSupervisor(frontmatter?.supervisor);

  return (
    <>
      <Seo
        uniqueOgImage={imageUrl || ''}
        url={href || (fields?.slug as string)}
        origin={origin}
        uniqueTitle={frontmatter?.title}
        uniqueDescription={excerpt}
      />
      <Layout fullwidth>
        <FullWidthContent>
          <LeftColumn>
            <Wrapper>
              <Inner>
                <ArticleInfo frontmatter={frontmatter} tagsMap={tagsMap} />
                {imageUrl ? <MainVisual src={imageUrl} /> : null}
                {frontmatter?.original && (
                  <OriginalWrapper>
                    <OriginalInfo
                      original={originalData}
                      authorLink={frontmatter.original.author_link || ''}
                      authorImagePublicUrl={
                        frontmatter.original.author_image_public_url || ''
                      }
                      isRequestedArticle={
                        frontmatter.requested_article || false
                      }
                    />
                  </OriginalWrapper>
                )}
                {/* biome-ignore lint/security/noDangerouslySetInnerHtml: Remarkから生成されたHTMLを直接表示する */}
                <TextPage dangerouslySetInnerHTML={{ __html: html || '' }} />
                {supervisor && <SupervisorInfo supervisor={supervisor} />}
                <ShareButtonsWrapper>
                  <ShareButtons url={location.href} />
                </ShareButtonsWrapper>
              </Inner>
            </Wrapper>
          </LeftColumn>
          <SidebarWrapper>
            <Sidebar pathname={pathname} />
          </SidebarWrapper>
        </FullWidthContent>
      </Layout>
    </>
  );
};

export const query = graphql`
  query ArticlePage($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        tags
        date(formatString: "YYYY年M月D日")
        image_public_url
        image {
          publicURL
        }
        requested_article
        original {
          author_link
          author
          author_image_public_url
          date
          title
          link
          extra
        }
        supervisor
      }
      excerpt(truncate: true, format: PLAIN, pruneLength: 100)
      fields {
        slug
      }
    }
    allSiteTag {
      nodes {
        slug
        title
      }
    }
  }
`;

export default ArticlePage;
