import styled from 'styled-components';
import iconQuote from '@/images/icon_quote.png';
import 'prismjs/themes/prism-twilight.css';
import 'prismjs/plugins/line-numbers/prism-line-numbers.css';
import 'remark-admonitions/styles/classic.css';

const TextPage = styled.div`
  img,
  video {
    width: 100%;
  }

  div[style^='width'] {
    max-width: 100% !important;
  }

  h2 {
    padding-bottom: 24px;
    margin-top: 55px;
    font-size: 26px;
    line-height: 1.6;
    border-bottom: 2px solid #707070;

    @media (max-width: 767px) {
      padding-bottom: 16px;
      font-size: 20px;
    }
  }

  h3 {
    margin-top: 55px;
    font-size: 24px;
    font-weight: bold;
    line-height: 1.6;

    @media (max-width: 767px) {
      margin-top: 44px;
      font-size: 18px;
    }

    &::before {
      padding-right: 15px;
      content: '';
      border-left: 5px solid #adb5bb;

      @media (max-width: 767px) {
        padding-right: 11px;
      }
    }
  }

  h4 {
    margin-top: 50px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.6;

    @media (max-width: 767px) {
      margin-top: 38px;
      font-size: 16px;
    }
  }

  p {
    margin-top: 25px;
    font-size: 17px;
    line-height: 2;

    @media (max-width: 767px) {
      margin-top: 24px;
      font-size: 15px;
      line-height: 1.9;
    }
  }

  a {
    color: #469af6;
    text-decoration: none;
  }

  ul {
    margin-top: 20px;
  }

  ul li {
    position: relative;
    padding-left: 1.8rem;
    font-size: 17px;
    line-height: 2;

    &:not(:first-of-type) {
      margin-top: 5px;
    }

    &::before {
      position: absolute;
      top: 13px;
      left: 5px;
      display: inline-block;
      width: 6px;
      height: 6px;
      content: '';
      background-color: #1b1b1b;
    }

    @media (max-width: 767px) {
      padding-left: 1.5rem;
      font-size: 15px;
    }
  }

  ol {
    margin-top: 20px;
    counter-reset: item;
  }

  ol li {
    padding-left: 1.7rem;
    font-size: 17px;
    line-height: 2;
    text-indent: -1.7rem;

    &:not(:first-of-type) {
      margin-top: 5px;

      @media (max-width: 767px) {
        margin-top: 8px;
      }
    }

    &::before {
      padding-right: 0.7rem;
      content: counter(item) '.';
      counter-increment: item;

      @media (max-width: 767px) {
        padding-right: 0.6rem;
      }
    }

    @media (max-width: 767px) {
      padding-left: 1.4rem;
      font-size: 15px;
      line-height: 1.9;
      text-indent: -1.4rem;
    }
  }

  em {
    font-style: italic;
  }

  code {
    font-size: 15px;
    line-height: 1;
  }

  pre .line-numbers-rows {
    padding: 1em;
    font-size: 15px;
    line-height: 1;
  }

  pre .line-numbers-rows span {
    padding: 0.3em 0;
  }

  blockquote {
    position: relative;
    padding: 0 42px;

    @media (max-width: 767px) {
      padding: 0 25px;
    }

    &::before,
    &::after {
      position: absolute;
      width: 24px;
      height: 24px;
      content: '';
      background-image: url(${iconQuote});

      @media (max-width: 767px) {
        width: 20px;
        height: 20px;
        background-size: 20px 20px;
      }
    }

    &::before {
      top: 4px;
      left: 0;

      @media (max-width: 767px) {
        top: 2px;
      }
    }

    &::after {
      right: 0;
      bottom: 4px;
      transform: rotate(180deg);

      @media (max-width: 767px) {
        bottom: 4px;
      }
    }
  }

  table {
    width: 100%;
    margin-top: 25px;
  }

  th {
    font-weight: bold;
    text-align: left;
  }

  td,
  th {
    padding: 10px;
    font-size: 15px;
    line-height: 2;
    border: 1px solid #e6e6e6;

    @media (max-width: 767px) {
      font-size: 14px;
    }
  }

  .admonition-info {
    background-color: #f6f6f6;
    border-left: 8px solid #1b1b1b;
  }

  .admonition-none {
    background-color: #f6f6f6;
    border-left: 8px solid #1b1b1b;

    div:first-child {
      display: none;
    }
  }

  .admonition-info h5 {
    color: #1b1b1b;
  }

  .admonition-info .admonition-icon svg {
    stroke: #1b1b1b;
    fill: #1b1b1b;
  }

  summary {
    cursor: pointer;
  }
`;

export default TextPage;
