import React from 'react';
import styled from 'styled-components';
import ArticleDate from '@/atoms/ArticleDate';
import ArticleTitle from '@/atoms/ArticleTitle';
import Tag from '@/atoms/Tag';

const TitleWrapper = styled.div`
  margin-top: 16px;

  @media (max-width: 767px) {
    margin-top: 12px;
  }
`;

const TagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;

  @media (max-width: 767px) {
    margin-top: 12px;
  }
`;

const TagItem = styled.li`
  margin-right: 11px;
  margin-bottom: 5px;
`;

type Props = {
  frontmatter: GatsbyTypes.Maybe<GatsbyTypes.Frontmatter>;
  tagsMap: Map<string, string>;
};

const ArticleInfo: React.FC<Props> = ({ frontmatter, tagsMap }) => (
  <>
    <ArticleDate>{frontmatter?.date}</ArticleDate>
    <TitleWrapper>
      <ArticleTitle>{frontmatter?.title}</ArticleTitle>
    </TitleWrapper>
    <TagList>
      {frontmatter?.tags?.map((tag) => {
        const label = tag || '';
        const labelText = tagsMap.get(label) || '';

        return (
          <TagItem key={label}>
            <Tag href={`/tag/${label}`} label={labelText} border />
          </TagItem>
        );
      })}
    </TagList>
  </>
);

export default ArticleInfo;
