import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

type Props = {
  url: string;
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;

  @media (max-width: 767px) {
    justify-content: flex-start;
  }
`;

const Item = styled.li`
  padding-bottom: 6px;

  &:not(:first-child) {
    margin-left: 10px;
  }

  &:nth-child(3) {
    padding-bottom: 0;
  }

  @media (max-width: 767px) {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
`;

const ShareButtons: React.FC<Props> = (props) => {
  const { url } = props;

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    const createPocketButton = document.createTextNode(`
      !function(d,i){if(!d.getElementById(i)){var j=d.createElement("script");j.id=i;j.src="https://widgets.getpocket.com/v1/j/btn.js?v=1";var w=d.getElementById(i);d.body.appendChild(j);}}(document,"pocket-btn-js");
    `);

    script.appendChild(createPocketButton);
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <Wrapper>
        <List>
          <Item>
            <a
              href="https://b.hatena.ne.jp/entry/"
              className="hatena-bookmark-button"
              data-hatena-bookmark-layout="vertical-normal"
              data-hatena-bookmark-lang="ja"
              title="このエントリーをはてなブックマークに追加"
            >
              <img
                src="https://b.st-hatena.com/images/v4/public/entry-button/button-only@2x.png"
                alt="このエントリーをはてなブックマークに追加"
              />
            </a>
          </Item>
          <Item>
            <a
              href="https://twitter.com/share?ref_src=twsrc%5Etfw"
              className="twitter-share-button"
              data-lang="ja"
              data-show-count="false"
            >
              Tweet
            </a>
          </Item>
          <Item>
            <div id="fb-root" />
            <div
              className="fb-like"
              data-href={url}
              data-width="84"
              data-layout="box_count"
              data-action="like"
              data-size="small"
              data-share="false"
            />
          </Item>
          <Item>
            {/* biome-ignore lint/a11y/useValidAnchor: Pocketのexampleに従う */}
            <a
              data-pocket-label="pocket"
              data-pocket-count="vertical"
              className="pocket-btn"
              data-lang="en"
            >
              pocket
            </a>
          </Item>
        </List>
      </Wrapper>
      <Helmet>
        <script
          async
          defer
          crossOrigin="anonymous"
          src="https://connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v10.0"
          nonce="jX8miYcO"
        />
        <script
          src="https://b.st-hatena.com/js/bookmark_button.js"
          charSet="utf-8"
          async
        />
        <script
          async
          src="https://platform.twitter.com/widgets.js"
          charSet="utf-8"
        />
      </Helmet>
    </>
  );
};

export default ShareButtons;
